import axios from "axios";

const state = {
  user: null,
  currentAppId: 0,
  currentApplication: null,
  currentInterface: 0,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  currentApplication: (state) => state.user.data.applications[state.currentAppId],
};

const actions = {
  async Register({dispatch}, form) {
    await axios.post('register', form)
    let UserForm = new FormData()
    UserForm.append('email', form.email)
    UserForm.append('password', form.password)
    await dispatch('LogIn', UserForm)
  },

  async LogIn({commit}, user) {

    let response = await axios.post("login", user);
    await commit("setUser", response);
  },
  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  },  
  async ChangeApp({ commit }, appid) {
		commit("changeapp", appid);
		
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },

  logout(state, user) {
    state.user = user;
  }, 
  changeapp(state, appid) {
    state.currentAppId = appid;
	
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};