<template>

  <b-container class="w100" v-if="isLoggedIn">
  <b-row>
    <b-col cols="1" id="appbar">
	<b-icon icon="bell-fill" light aria-hidden="true" class="notifications" id="notify"></b-icon>
	<b-tooltip target="notify" placement="right">Nu ai notificari!</b-tooltip>
	<div class="mb-2 applications_container" v-if="StateUser.data.applications.length > 0">
	<div v-for="(application, index) in StateUser.data.applications" id="appholder" v-bind:key="index">
	
    <b-avatar button rounded="sm" v-bind:icon="application.icon" @click="loadapp(index)" v-bind:id="index" :style="{ 'background-color': application.color }" ></b-avatar>
	<b-tooltip target="index" placement="right">{{application.name}}</b-tooltip>
	
	<div class="extender" v-if="application.id == currentApplication.id" :style="{ 'background-color': application.color }"></div>
	</div>
	<b-avatar rounded="sm" icon="plus" class="newapp" v-b-modal.modal-addnewapp></b-avatar>
	<b-modal id="modal-addnewapp" centered title="Creaza Aplicatie noua (dezactivat)">
       <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label="Nume" label-for="name-input" invalid-feedback="Name is required">
         <b-form-input id="name-input" required ></b-form-input>
        </b-form-group>    
		<b-form-group label="Icon" label-for="icon-input" invalid-feedback="Icon is required">
         <b-form-input id="icon-input" required ></b-form-input>
        </b-form-group>		
		<b-form-group label="Culoare" label-for="color-input" invalid-feedback="Color is required">
         <b-form-input id="color-input" required ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
   </div>
    <b-dropdown id="dropdown-dropup" dropup text="" variant="light" pill class="m-1">
 <template #button-content>
 <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
      </template>
        <b-dropdown-item-button>
         <b-icon icon="person-circle" light aria-hidden="true"></b-icon>
          Setari (dezactivat) 
        </b-dropdown-item-button>
		<b-dropdown-item-button  @click="logout">
		<b-icon icon="arrow-bar-right" aria-hidden="true"></b-icon>
          Deconectare 
 </b-dropdown-item-button>
 </b-dropdown>
	</b-col>
    <b-col cols="1" id="interfacebar" :style="{ 'background-color': currentApplication.color }">
	<h5>{{currentApplication.name}}</h5>
	<div id="separator"></div>
	
	<ul>
		<li @click="loadinterface(index)" :class="[currentInterface,{ 'active' : index === currentInterface }]" v-for="(appinterface, index) in currentApplication.interfaces"  v-bind:key="index">{{appinterface.name}}</li>
	</ul>
	</b-col>
    <b-col cols="10" id="databar">
<div v-if="currentInterfaceData">
  <b-navbar type="light">
    <b-navbar-brand href="#">{{currentInterfaceName}}</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-form-input size="sm" class="mr-sm-2 search" placeholder="Cautare" right></b-form-input>
	<b-avatar  icon="plus" class="newentry" v-b-modal.modal-addnewentry></b-avatar>
	<b-modal id="modal-addnewentry" centered title="Adauga">
       <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label="Nume" label-for="Nume-input" invalid-feedback="Nume is required">
         <b-form-input id="Nume-input" required ></b-form-input>
        </b-form-group>    
		<b-form-group label="Prenume" label-for="Prenume-input" invalid-feedback="Prenume is required">
         <b-form-input id="Prenume-input" required ></b-form-input>
        </b-form-group>		
		<b-form-group label="Cnp" label-for="Cnp-input" invalid-feedback="Cnp is required">
         <b-form-input id="Cnp-input" required ></b-form-input>
        </b-form-group>		
		<b-form-group label="Saptamana" label-for="Saptamana-input" invalid-feedback="Saptamana is required">
         <b-form-input id="Saptamana-input" required ></b-form-input>
        </b-form-group>		
		<b-form-group label="Sector" label-for="Sector-input" invalid-feedback="Sector is required">
         <b-form-input id="Sector-input" required ></b-form-input>
        </b-form-group>		
		<b-form-group label="Viza" label-for="viza-input" invalid-feedback="viza is required">
         <b-form-input id="viza-input" required ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </b-navbar>
 <b-table v-if="currentInterfaceName === 'Beneficiari'"
      :sticky-header="stickyHeader"
      :no-border-collapse="noCollapse"
      responsive
      :items="itemsBeneficiari"
      :fields="fieldsBeneficiari"
    >
    </b-table>
	<b-table v-if="currentInterfaceName === 'Vouchere'"
      :sticky-header="stickyHeader"
      :no-border-collapse="noCollapse"
      responsive
      :items="itemsVouchers"
      :fields="fieldsVouchers"
    >

    </b-table>	
	<b-table v-if="currentInterfaceName === 'Alerte'"
      :sticky-header="stickyHeader"
      :no-border-collapse="noCollapse"
      responsive
      :items="itemsAlerte"
      :fields="fieldsAlerte"
    >
    </b-table>
	<div class="nodata">
	<h4 v-if="currentInterfaceName === 'In desfasurare'">Nu exista concursuri in desfasurare!</h4>
	<h4 v-if="currentInterfaceName === 'Rezultate'">Nu exista Rezultate!</h4>
	<h4 v-if="currentInterfaceName === 'Servicii Sociale'">Nu exista Servicii Sociale!</h4>
	<h4 v-if="currentInterfaceName === 'Stimulente'">Nu exista Stimulente!</h4>
	<h4 v-if="currentInterfaceName === 'Contracte'">Nu exista Contracte!</h4>
	</div>
</div>
	
	
	</b-col>
  </b-row>

</b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Dashboard",
    data: function() {
         return  {
           currentInterface: 0,
           currentInterfaceName: '',
           currentInterfaceData: null,
         stickyHeader: true,
        noCollapse: false,
        fieldsBeneficiari: [
          { key: 'Nume', stickyColumn: true, isRowHeader: true, variant: 'primary' },
          'Prenume',
          'Cnp',
          'Saptamana',
          'Sector',
          'Viza',
          'Telefon',
          'Email',
          'Vouchere',
          'Observatii'
        ],    
		fieldsVouchers: [
          'Cod',
          'Valabil',
          'Eliberat',
          'Valoare'
        ],	
		fieldsAlerte: [
          'Identificare',
          'Adresa',
          'Imagini',
          'Observatii'
        ],
        itemsBeneficiari: [
          { Nume: "Adobroae", Prenume: "Adriana", Cnp: "2900113045365", Saptamana: "19; VII29", Sector: "5", Viza: "Nu", Telefon: "0763769789", Email: "anairda.unid@gmail.com", Vouchere: "I SI II", Observatii: "ELIB.29.10.20"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
          { Nume: "Ionita", Prenume: "Mihaela-Eliza", Cnp: "2840920152495", Saptamana: "VI-10/V II 24", Sector: "1", Viza: "Nu", Telefon: "0724190153", Email: "eliza.mihaela.ionita@gmail.com", Vouchere: "I SI II", Observatii: "Imputernicit-Ionita Constantin"},
        ], itemsVouchers: [
          { Cod: "VC54454", Valabil: "Da", Eliberat: "12 august 2021", Valoare: "1000 RON"},
          { Cod: "ACd4433", Valabil: "Da", Eliberat: "15 aprilie 2020", Valoare: "1000 RON"},
          { Cod: "V854311", Valabil: "Da", Eliberat: "11 ianuarie 2020", Valoare: "1000 RON"},
          { Cod: "AD53221", Valabil: "Da", Eliberat: "21 august 2021", Valoare: "1000 RON"},
          { Cod: "UU54453", Valabil: "Da", Eliberat: "29 iunie 2021", Valoare: "1000 RON"},
          { Cod: "JH54234", Valabil: "Da", Eliberat: "30 august 2021", Valoare: "1000 RON"},
          { Cod: "LK54234", Valabil: "Da", Eliberat: "1 ianuarie 2021", Valoare: "1000 RON"},
          { Cod: "NK54234", Valabil: "Da", Eliberat: "22 august 2021", Valoare: "1000 RON"},
          { Cod: "IK54423", Valabil: "Da", Eliberat: "11 ianuarie 2020", Valoare: "1000 RON"},
          { Cod: "LL54234", Valabil: "Da", Eliberat: "20 februarie 2021", Valoare: "1000 RON"},
          { Cod: "SL54323", Valabil: "Da", Eliberat: "19 ianuarie 2020", Valoare: "1000 RON"},
          { Cod: "OO44531", Valabil: "Da", Eliberat: "15 august 2021", Valoare: "1000 RON"},
          { Cod: "AE54432", Valabil: "Da", Eliberat: "17 martie 2020", Valoare: "1000 RON"},
          { Cod: "LO54400", Valabil: "Da", Eliberat: "22 iunie 2021", Valoare: "1000 RON"},
          { Cod: "ZZ54499", Valabil: "Da", Eliberat: "6 august 2021", Valoare: "1000 RON"},
          { Cod: "ED54234", Valabil: "Da", Eliberat: "3 septembrie 2021", Valoare: "1000 RON"},
          { Cod: "MN54433", Valabil: "Nu", Eliberat: "3 februarie 2019", Valoare: "1000 RON"},
          { Cod: "KKH5412", Valabil: "Da", Eliberat: "5 august 2021", Valoare: "1000 RON"},
          { Cod: "PIJH434", Valabil: "Da", Eliberat: "12 februarie 2021", Valoare: "1000 RON"},
          { Cod: "QKDN411", Valabil: "Nu", Eliberat: "13 septembrie 2019", Valoare: "1000 RON"},
          
        ], itemsAlerte: [
          { Identificare: "42e2rfsdfsasdfs", Adresa: "Zona garii de nord", Imagini: "../uploads/2016/01/oamenii-strazii.jpg", Observatii: "fara adopost ingheata pe strazi"},

          
        ]
         }
    },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },
	StateUser: function() {
      return this.$store.getters.StateUser;
    },
	currentApplication: function() {
      return this.$store.getters.currentApplication;
    }
	
  },
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },  
    loadapp: function (index) {
		this.currentInterfaceData = null;
		let token =  this.$store.getters.StateUser.data.token;
		let appid = this.$store.getters.StateUser.data.applications[index].id;
		const AuthStr = 'Bearer '.concat(token); 
		this.$store.dispatch("ChangeApp", index);
		axios.get("applications/"+appid, { headers: { Authorization: AuthStr } }).then(response => {
		this.$store.getters.StateUser.data.applications[index].interfaces = response.data.data.interfaces;
		
           
       })
    },
	loadinterface: function (index) {
		this.currentInterface = index;
		this.currentInterfaceName = this.$store.getters.currentApplication.interfaces[index].name;
		let token =  this.$store.getters.StateUser.data.token;
		let interfaceid = this.$store.getters.currentApplication.interfaces[index].id;
		let appid = this.$store.getters.currentApplication.id;
		const AuthStr = 'Bearer '.concat(token); 
		axios.get("applications/"+appid+'?interface='+interfaceid, { headers: { Authorization: AuthStr } }).then(response => {
		this.currentInterfaceData = response.data;
           
       })
    }
  },
};
</script>
<style>
.container.w100{
width: 100%;
    height: 100vh;
    margin: 0;
    max-width: 100%;
}
#appbar{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-flow: column nowrap;
    background-color: #1c2227;
   padding: 8px;
    height: 100%;
    max-height: 100%;
    width: 60px;
    flex-shrink: 0;
	height: 100vh;
}
#interfacebar{
margin-left: 0px;
    height: 100vh;
    background: #343843;
 padding: 0;
     width: 270px;
}
#databar{
margin-left: 0px;
    height: 100vh;
    background: #ffffff;
 padding: 0;
     width: calc(100% - 330px);
}
#dropdown-dropup{
    position: absolute;
    bottom: 12px;
    width: 32px;
    padding: 0 !important;
    margin: 5px !important;
    border-radius: 50%;
    height: 32px;
}
.dropdown-menu.show {
    margin-top: -15px !important;
 top: -15px !important;
}
.dropdown-menu .dropdown-item {
font-size: 1rem;

}

.dropdown-menu .dropdown-item:hover .b-icon{

}
.dropdown-menu li .b-icon{
padding: 2px;
    margin: -1px 0px;

}
#dropdown-dropup .dropdown-toggle{
    width: 32px;
    padding: 4px 5px;
}
.dropdown-toggle::after{
	content:none!important;
}
.dropdown-menu { 
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.notifications{
    width: 32px;
    height: 32px;
    margin: 5px !important;
    padding: 6px;
	border-radius: 50%;
    color: #919191;
	cursor:pointer;
}
.notifications:hover{
color: #fff;
background:rgba(0, 0, 0, 0.15);
}
.applications_container {
    margin-top: 50px;
    border-radius: 0.25rem;
    padding: 5px;
	background:rgba(255, 255,255, 0.05);
}
.applications_container .b-avatar{
    border-radius: 0.25rem;
	border:1px solid rgba(0, 0,0, 0.25);
	width:34px;
	height:34px;
	cursor:pointer;
	color:#fff;
	margin: 5px 0px;
}
.applications_container .b-avatar.newapp {
 
	border:1px solid rgba(0, 0, 0, 0);

}

.modal-content .form-control{
    height: 36px;
    border: 1px solid #ccc;
}
.modal-content button.close{
    border: 0px;
    background: transparent;
}
#interfacebar h5 {
    margin: 15px 0px;
	color:rgba(255,255,255,0.8);
	text-align:center;
	
}
#appholder{
position:relative;
}
#appholder .extender{
    position: absolute;
    top: 16px;
    width: 15px;
    height: 10px;
    right: -14px;
}

#interfacebar ul{
    margin-top: 45px;
    padding: 0;
 }
 #interfacebar ul li{
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-flow: column nowrap;
   display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    transition: all 0.2s ease-in-out 0s;
    color: rgb(31, 33, 35);
    max-width: 100%;
    margin: 1px 5px;
    padding: 6px 8px 4px 11px;
    font-size: 15px;
	color: #fff;
    border-radius: 5px;
	text-shadow: none;
    cursor: pointer;
 } 
 #interfacebar ul li.active, #interfacebar ul li:hover{
   color: #1c2227;
    font-weight: 600;
    background-color: white !important;
 }
 
 #separator {
    background-color: rgba(255, 255, 255, 0.2);
    align-self: stretch;
    height: 1px;
 }
.navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    height: 54px;
    padding: 27px 25px;
} 
.navbar-light .search{
    height: 30px;
    font-size: 14px;
    line-height: 18px;
    padding: 20px 16px 16px 16px;
    border: 1px solid #dbe1e7;
}

.b-table-sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 54px)!important;
}
.nodata h4{
    text-align: center;
    line-height: calc(100vh - 54px);
}
</style>